import {Marker, useMapEvents} from "react-leaflet";
import L, {LatLngExpression, LeafletMouseEvent} from "leaflet";
import React, {FC} from "react";
import LocationIcon from "@assets/icon/moto.svg";
import {LocationMarker} from "@typesApp/map";

const customIcon = L.icon({
    iconUrl: LocationIcon,
    iconSize: [50, 50], // Размер иконки
    iconAnchor: [12.5, 41], // Точка, где иконка якорится к координатам
    popupAnchor: [0, -41] // Точка всплывающего окна относительно якоря иконки
});

interface MyLocationProps {
    markerPosition: null | LocationMarker,
    changePosition: (value: LatLngExpression) => void
}
export const MyLocation: FC<MyLocationProps> = ({markerPosition,changePosition}) => {
    useMapEvents({
        click(e: LeafletMouseEvent) {
            const { lat, lng } = e.latlng;
            const newLocation: LatLngExpression = [lat, lng];
            changePosition(newLocation);
        },
    });
    return markerPosition ? <Marker position={[markerPosition?.lat,markerPosition?.lot]} icon={customIcon} /> : null;
}