import { FC, useState } from "react";
import combiningClasses from "@utils/combiningClasses";
import styles from './style.module.scss';

interface OptionsSwitch {
    labels: [string, string];
    values: [string, string];
    onSwitch: (value: string) => void;
}

interface ToggleSwitchProps {
    options: OptionsSwitch;
}

export const ToggleSwitch: FC<ToggleSwitchProps> = ({ options }) => {
    const [activeValue, setActiveValue] = useState<string>(options.values[0]);

    const onSwitch = () => {
        const newValue = activeValue === options.values[0] ? options.values[1] : options.values[0];
        setActiveValue(newValue);
        options.onSwitch(newValue);
    };

    return (
        <div className={styles.switcher}>
            <p className={
                combiningClasses(
                    'fb',
                    'f14',
                    activeValue === options.values[0] ? styles.active : styles.noactive
                )
            }>
                {options.labels[0]}
            </p>
            <div onClick={onSwitch} className={styles.switch}>
                <div className={combiningClasses(styles.el, activeValue === options.values[1] ? styles.switchRight : styles.switchLeft)} />
            </div>
            <p className={
                combiningClasses(
                    'fb',
                    'f14',
                    activeValue === options.values[1] ? styles.active : styles.noactive
                )
            }>
                {options.labels[1]}
            </p>
        </div>
    );
};
