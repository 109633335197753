import styles from './style.module.scss'
import {Col, Container, Row} from "react-bootstrap";
import {InputPassword} from "@common/components/InputPassword/InputPassword";
import {SubmitButton} from "@common/components/SubmitButton/SubmitButton";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {forgetPassword, login, updateTypeForm} from "@store/slices/userSlice";
import {typeForm} from "@enums/auth";
import {ChangeEvent, useState} from "react";
import {InputPhone} from "@common/components/InputPhone/InputPhone";
import {InputMaskChangeEvent} from "primereact/inputmask";
import {ForgetPasswordReq, ForgetPasswordRes, LoginReq} from "@typesApp/userApi";
import {formatPhoneNumber} from "@utils/formatPhone";
import {Role} from "@enums/role";
import {ToggleSwitch} from "@common/components/ToggleSwitch/ToggleSwitch";
import combiningClasses from "@utils/combiningClasses";
import {Notification} from "@utils/Notification/Notification";
import {Info} from "@common/components/Info/Info";
import {PASSWORD_HELP} from "@const/helpText";

export const ResetForm = () => {
    const [password,setPassword] = useState<string>('')
    const [phone,setPhone] = useState<string>('')
    const [role,setRole] = useState<Role>(Role.CLIENT)
    const dispatch = useAppDispatch()
    const onNav = () => {
        dispatch(updateTypeForm(typeForm.LOGIN))
    }
    const onChangePassword = (e: ChangeEvent) => {
        const password: string =  (e.target as HTMLInputElement).value
        setPassword(password)
    }
    const onChangePhone = (e: InputMaskChangeEvent) => {
        setPhone(e.target.value as string)
    }
    const onSwitch = (value: string) => {
        setRole(value as Role)
    }
    const onSubmit = () => {
        if(password.length < 8)  {
            Notification.error('У пароля должно быть минимум 8 символов')
            return;
        }
        if(!/\d+/i.test(password)) {
            Notification.error('У пароля должна быть минимум 1 цифра')
            return;
        }
        if(!/[a-z]+/i.test(password)) {
            Notification.error('У пароля должeн быть минимум 1 латинский символ')
            return;
        }
        if(phone.length){
            const data: ForgetPasswordReq = {
                new_password: password,
                role,
                phone: formatPhoneNumber(phone),
            }
            dispatch(forgetPassword(data))
        } else {
            Notification.error('Поле номера телефона не заполнено')
        }
    }
    return (
        <Container fluid>
            <Row>
                <Col style={{minHeight: '100vh'}} className='d-flex align-items-center justify-content-center'>
                    <div className={styles.form}>
                        <h1 className='fb f25'>Сброс пароля</h1>
                        <div className={styles.content}>
                            <InputPhone width='100%' value={phone} onChange={onChangePhone}
                                        placeholder='+9 (999) 999-99-99'/>
                            <div className={styles.help}>
                                <InputPassword width='100%' value={password} onChange={onChangePassword}
                                               placeholder='Введите новый пароль'/>
                                <Info content={PASSWORD_HELP}/>
                            </div>
                            <div className={combiningClasses(styles.flex, 'fm', 'f14')}>
                                <p className={styles.text}>Выбирите аккаунт для которого меняете пароль: </p>
                                <ToggleSwitch options={{
                                    labels: ['Клиент', 'Водитель'],
                                    values: [Role.CLIENT, Role.DRIVER],
                                    onSwitch,
                                }}/>
                            </div>
                            <SubmitButton width='100%' text='Изменить' onClick={onSubmit}/>
                            <p className={combiningClasses('fm', 'f16', styles.action)} onClick={onNav}>К
                                авторизации</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
