import styles from './style.module.scss'
import {ChangeEvent, FC} from "react";
import combiningClasses from "@utils/combiningClasses";
interface InputTextProps {
    value: string,
    onChange: (e: ChangeEvent) => void,
    placeholder: string,
    width?: string,
    disabled?: boolean
}
export const InputText: FC<InputTextProps> = ({disabled,width,value,placeholder, onChange}) => {
    return <input
        onChange={onChange}
        placeholder={placeholder}
        className={combiningClasses(styles.input, 'fm','f14')}
        type='text'
        disabled={disabled}
        style={{width}}
        value={value}
    />
}