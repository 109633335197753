import {load} from "@2gis/mapgl";

export class MapGis {
    private map: any = null;
    private markers: any[] = [];
    private direction: any = null;
    private readonly apiKey: string = '';

    constructor(apiKey: string) {
        this.apiKey = apiKey
    }

    // загружаем модули
    private async initMapApi() {
        try {
            return await load();
        } catch (error) {
            console.error(error);
        }
    }

    //получаем карту
    public getMap() {
        return this.map
    }

    //получаем маркеры
    public getMarkers() {
        return this.markers
    }

    // получаем навигацию
    public getDirection() {
        return this.direction
    }
    // удаляем карту и всё что с ней связано
    public removeMap () {
        if(this.map) {
            this.map.destroy();
            this.map = null
            this.markers = []
            this.direction = null
        }
    }

    // создаём карту с траффиком
    public async setMap(id: string,location: number[]) {
        if(this.map) this.map = null
        const mapApi = await this.initMapApi()
        if(mapApi && !this.map){
            this.map = new mapApi.Map(id,{
                center: location,
                zoom:10,
                trafficControl: true,
                key: this.apiKey,
            })
            this.map.showTraffic();

        }
    }
}