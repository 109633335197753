import {Offcanvas, Tab, Tabs} from "react-bootstrap";
import styles from './style.module.scss'
import {FC, useEffect, useState} from "react";
import {ButtonShow} from "@common/components/ButtonShow/ButtonShow";
import combiningClasses from "@utils/combiningClasses";
import {getUser, logout} from "@store/slices/userSlice";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {IconButton} from "@common/components/IconButton/IconButton";
import ExitIcon from '@assets/icon/exit.svg'
import {User} from "@typesApp/userApi";
import {formatRole} from "@utils/formatRole";
import {CreateOrder} from "@pages/MapPage/components/CreateOrder/CreateOrder";
import {ListOrders} from "@pages/MapPage/components/ListOrders/ListOrders";
import {Role} from "@enums/role";
import {useAppSelector} from "@hooks/useAppSelector";
import {SubmitButton} from "@common/components/SubmitButton/SubmitButton";
import {activateOrderDriver, clearOrders, deactivateOrderDriver} from "@store/slices/orderSlice";
import {formatTime} from "@utils/formatTime";
import {Notification} from "@utils/Notification/Notification";
import {ActiveOrder} from "@pages/MapPage/components/ActiveOrder/ActiveOrder";
import {EyeSlashIcon} from "primereact/icons/eyeslash";
interface PanelActionsProps {
    user: User | null,
    getMyLocation: () => void,
}
export const PanelActionsMobile: FC<PanelActionsProps> = ({user,getMyLocation}) => {
    const [show, setShow] = useState<boolean>(false);
    const [activeTab,setActiveTab] = useState<string>('decoration')
    const isProgressActive = useAppSelector(({order})=> order.isProgressOrder)
    const dispatch = useAppDispatch()
    const currentOrder = useAppSelector(({user}) => user.currentUserOrder )
    const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false);
    const [timer, setTimer] = useState<number>(0); // например, в секундах
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectTab = (key: string | null) => {
        setActiveTab(key as string)
    }
    const onLogout = () => {
        dispatch(logout())
    }
    const onComplete = async () => {
        if(user?.driver_data?.is_busy) {
            Notification.warning("Сначала завершите текущий заказ")
        } else if(user?.driver_data?.is_activate === 1) {
            await dispatch(deactivateOrderDriver())
            await dispatch(getUser())
            dispatch(clearOrders())
        }

    }
    useEffect(() => {
        if(user?.role === Role.DRIVER){
            setActiveTab('orderHistory')
        }
        if(user?.role === Role.CLIENT && currentOrder){
            setActiveTab('orderActive')
        }
        if(user?.role === Role.DRIVER && user?.driver_data?.is_activate === 1){
            setIsTimerRunning(true)
        } else {
            setIsTimerRunning(false)
        }
    }, [user]);
    const activateOrder = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    await dispatch(activateOrderDriver({
                        location_activate: {
                            lat: latitude,
                            lot: longitude
                        }
                    }))
                    await dispatch(getUser())
                },
                (error) => {
                    console.log(error.message)
                }
            );
        } else {
            console.log("Не разрешён доступ к местоположению")
        }
    }
    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (isTimerRunning) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isTimerRunning]);

    return (
        <>
            <div className={combiningClasses(styles.wrap, styles.open)}>
                <ButtonShow onClick={handleShow} open={show}/>
            </div>
            <Offcanvas placement={'top'} show={show}>
                <Offcanvas.Body>
                    <div className='d-flex justify-content-start align-items-center' style={{columnGap: 5}}>
                        <p className={combiningClasses('fb', 'f16', styles.name)}>{user?.name}</p>
                        <p className={combiningClasses('fb', 'f12', styles.role)}>{formatRole(user?.role)}</p>
                        <div className={styles.exit}>
                            <IconButton onClick={onLogout} icon={ExitIcon}/>
                        </div>
                    </div>
                    <div>
                        {
                            user?.role === Role.DRIVER && user?.driver_data?.is_activate === 0 ?
                                <div>
                                    <SubmitButton color={'#058fe0'} text='Начать работу' onClick={activateOrder}/>
                                </div> : null
                        }
                        {
                            user?.role === Role.DRIVER && user?.driver_data?.is_activate === 1 ?
                                <div>
                                    <SubmitButton color={'#058fe0'} text='Завершить' onClick={onComplete}/>
                                    <p className='fm f14'>Время работы: {formatTime(timer)}</p>
                                </div>: null
                        }
                    </div>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={activeTab}
                        onSelect={selectTab}
                        className={styles.tabs}
                    >
                        {
                            (() => {
                                switch (user?.role) {
                                    case Role.CLIENT:
                                    case Role.ADMIN:
                                        if(!currentOrder){
                                            return (
                                                <Tab eventKey="decoration" title="Оформление заказа">
                                                    <CreateOrder/>
                                                </Tab>
                                            )
                                        } else {
                                            return null
                                        }
                                    case Role.DRIVER:
                                        return null
                                    default:
                                        return
                                }
                            })()
                        }
                        {currentOrder && user ? (
                            <Tab eventKey="orderActive" title="Активный заказ">
                                <ActiveOrder order={currentOrder} user={user}/>
                            </Tab>
                        ) : null}
                        <Tab eventKey="orderHistory" title="История заказов">
                            <ListOrders/>
                        </Tab>
                    </Tabs>
                </Offcanvas.Body>
                <div className={combiningClasses(styles.wrap, styles.close)}>
                    <ButtonShow onClick={handleClose} open={show}/>
                </div>
            </Offcanvas>
        </>
    );
}