import styles from './style.module.scss'
import {useAppSelector} from "@hooks/useAppSelector";
import {OrderElement} from "@pages/MapPage/components/OrderElement/OrderElement";
import combiningClasses from "@utils/combiningClasses";
import {FC, useEffect, useState} from "react";
import {OrderSelect} from "@pages/MapPage/components/OrderSelect/OrderSelect";
import {Order} from "@typesApp/order";
import {Role} from "@enums/role";
import {SubmitButton} from "@common/components/SubmitButton/SubmitButton";
import {MapApi} from "@api/MapApi";
import {CurrentOrder, User} from "@typesApp/userApi";
import {formatPhoneNumberBack} from "@utils/formatPhone";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {cancelOrderClient, cancelOrderDriver, completeOrder} from "@store/slices/orderSlice";
import {getUser} from "@store/slices/userSlice";
interface ActiveOrderProps{
    order: CurrentOrder,
    user: User
}
export const ActiveOrder: FC<ActiveOrderProps> = ({order,user}) => {
    const [address, setAddress] = useState<string>('-')
    const [comment, setComment] = useState<string>('-')
    const dispatch  = useAppDispatch()
    const cancel = async () => {
        if(user.role === Role.DRIVER){
            await dispatch(cancelOrderDriver(order.id))
        }
        if(user.role === Role.CLIENT){
            await dispatch(cancelOrderClient(order.id))
        }
        dispatch(getUser())
    }
    const succesfull = async () => {
        await dispatch(completeOrder(order.id))
        dispatch(getUser)
    }
    useEffect(() => {
        if (order) {
            if (order.client_comment?.length) {
                setComment(order.client_comment)
            } else {
                setComment('-')
            }
            if (order.location_start) {
                MapApi.reverseGeocode([
                    order.location_start.lat,
                    order.location_start.lot
                ])
                    .then(address => {
                        setAddress(address)
                    })
                    .catch(err => {
                        console.log(err)
                        setAddress('-')
                    })
            }
        }
    }, [order]);
    return (
        <div className={styles.info}>
            {
                user.role === Role.CLIENT ? (
                    <div className={styles.user}>
                        <p className={combiningClasses('fb', 'f16')} style={{color: '#00600c'}}>Водитель:</p>
                        <div>
                            <p>{order.driver.name}</p>
                            <p>{formatPhoneNumberBack(order.driver.phone)}</p>
                            <p>{order.driver.email}</p>
                        </div>
                    </div>
                ): null
            }
            {
                user.role === Role.DRIVER ? (
                    <div className={styles.user}>
                        <p className={combiningClasses('fb', 'f16')} style={{color: '#00600c'}}>Клиент:</p>
                        <div>
                            <p>{order.client.name}</p>
                            <p>{formatPhoneNumberBack(order.client.phone)}</p>
                            <p>{order.client.email}</p>
                        </div>
                    </div>
                ): null
            }
            <div className={styles.blockDate}>
                <p className={combiningClasses('fm', 'f16', styles.type)}><span
                    style={{color: '#00600c', fontWeight: 700}}>Тип:</span> {order?.type}</p>
            </div>

            <div className={styles.block}>
                <p className={combiningClasses('fm', 'f16', styles.address)}><span
                    style={{color: '#00600CFF', fontWeight: 700}}>Куда:</span> {address}</p>
            </div>
            <div className={styles.block}>
                <p className={combiningClasses('fm', 'f16', styles.comment)}><span
                    style={{color: '#00600CFF', fontWeight: 700}}>Комментарий:</span> {comment}</p>
            </div>
            <div className={styles.action}>
                {user.role === Role.DRIVER ? <SubmitButton text='Завершить' onClick={succesfull}/>: null}
                <SubmitButton color={'grey'} text='Отменить' onClick={cancel}/>
            </div>
        </div>
    );
}