import styles from './styles.module.scss'
import {FC} from "react";
import ArrowIcon from '@assets/icon/arrow.svg'
interface ButtonShowProps {
    onClick: () => void,
    width?: string,
    open: boolean
}
export const ButtonShow:FC<ButtonShowProps> = ({onClick, open,width}) => {
    return (
        <button style={{width}} className={styles.button} onClick={onClick}>
            {
                open ? <img src={ArrowIcon} alt="Закрыть"/> : <img src={ArrowIcon} style={{
                    transform: 'rotate(180deg)'
                }} alt="Открыть"/>
            }
        </button>
    )
}