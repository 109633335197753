import styles from './style.module.scss'
import {Col, Container, Row} from "react-bootstrap";
import {InputText} from "@common/components/InputText/InputText";
import {InputPassword} from "@common/components/InputPassword/InputPassword";
import {SubmitButton} from "@common/components/SubmitButton/SubmitButton";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {registerStep1, updateTypeForm} from "@store/slices/userSlice";
import {typeForm} from "@enums/auth";
import {ChangeEvent, useState} from "react";
import {InputPhone} from "@common/components/InputPhone/InputPhone";
import {InputMaskChangeEvent} from "primereact/inputmask";
import {generatePassword} from "@utils/generatePassword";
import {formatPhoneNumber} from "@utils/formatPhone";
import {OnboardingReq} from "@typesApp/userApi";
import {Notification} from "@utils/Notification/Notification";
import combiningClasses from "@utils/combiningClasses";
import {Info} from "@common/components/Info/Info";
import {NAME_HELP, PASSWORD_HELP} from "@const/helpText";
export const RegisterFormClient = () => {
    const dispatch = useAppDispatch()
    const [password,setPassword] = useState<string>('')
    const [phone,setPhone] = useState<string>('')
    const [name,setName] = useState<string>('')
    const onNav = () => {
        dispatch(updateTypeForm(typeForm.LOGIN))
    }
    const onChangePassword = (e: ChangeEvent) => {
        const password: string =  (e.target as HTMLInputElement).value
        setPassword(password)
    }
    const onChangePhone = (e: InputMaskChangeEvent) => {
        setPhone(e.target.value as string)
    }
    const onChangeName = (e: ChangeEvent) => {
        const name: string =  (e.target as HTMLInputElement).value
        setName(name)
    }
    const onGeneratePassword = () => {
        setPassword(generatePassword())
    }
    const onSubmit = () => {
        if(!name.length) {
            Notification.error('Поле имени не заполнено')
            return;
        }
        if(password.length < 8)  {
            Notification.error('У пароля должно быть минимум 8 символов')
            return;
        }
        if(!/\d+/i.test(password)) {
            Notification.error('У пароля должна быть минимум 1 цифра')
            return;
        }
        if(!/[a-z]+/i.test(password)) {
            Notification.error('У пароля должeн быть минимум 1 латинский символ')
            return;
        }
        if(phone.length){
            const data: OnboardingReq= {
                phone: formatPhoneNumber(phone),
                name,
                password,
            }
            dispatch(registerStep1(data))
        } else {
            Notification.error('Поле номера телефона не заполнено')
        }
    }
    return (
        <div className={styles.page}>
            <p className={combiningClasses(styles.driver, 'fb','f20')}>Зарегистрироваться как водитель</p>
            <Container fluid>
                <Row>
                    <Col style={{minHeight: '100vh'}} className='d-flex align-items-center justify-content-center'>
                        <div className={styles.form}>
                            <h1 className='fb f25'>Регистрация клиента</h1>
                            <div className={styles.content}>
                                    <div style={{width: "100%"}} className={styles.help}>
                                        <InputText width='100%' value={name} onChange={onChangeName} placeholder='Имя'/>
                                        <Info content={NAME_HELP}/>
                                    </div>
                                <div className={styles.password}>
                                    <div className={styles.help}>
                                        <InputPassword width='100%' value={password} onChange={onChangePassword} placeholder='Пароль'/>
                                        <Info content={PASSWORD_HELP}/>
                                    </div>
                                        <p className='fm f16' onClick={onGeneratePassword}>Сгенерировать пароль</p>

                                </div>
                                <InputPhone width='100%' value={phone} onChange={onChangePhone} placeholder='+9 (999) 999-99-99'/>
                                <SubmitButton width='100%' text='Зарегистрироваться' onClick={onSubmit}/>
                                <p className='fm f16' onClick={onNav} >Войти</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}