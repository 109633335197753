import api from "@api/index";
import {CreateOrderData, ReqActivate, ResOrder, ResOrders, ResOrdersActive, ResTypeOrders} from "@typesApp/ordersApi";

export class OrderApi {
    public static getStatusAndTypes() {
        return api.get<ResTypeOrders>('/v1/data-order-enums')
    }
    public static getOrders() {
        return api.get<ResOrders>('/v1/orders')
    }
    public static getOrdersDriver() {
        return api.get<ResOrders>('/v1/driver/orders')
    }
    public static getOrdersHistory() {
        return api.get<ResOrders>(`/v1/driver/orders`)
    }
    public static getOrdersActive() {
        return api.get<ResOrdersActive>('/v1/driver/active/orders')
    }
    public static getOrder(id: number) {
        return api.get<ResOrder>(`/v1/orders/${id}`)
    }
    public static takeOrder(id: number) {
        return api.post<ResOrder>(`/v1/driver/orders/${id}/take`)
    }
    public static activateOrder(payload: ReqActivate) {
        return api.post<ResOrder>(`/v1/drivers/activate`,payload)
    }
    public static deactivateOrder() {
        return api.post<ResOrder>("/v1/drivers/deactivate")
    }
    public static getOrderDriver(id: number) {
        return api.get<ResOrder>(`/v1/driver/orders/${id}`)
    }

    public static getOrderDriverActive(id: number) {
        return api.get<ResOrder>(`/v1/driver/active/orders/${id}`)
    }
    public static createOrder(data: CreateOrderData) {
        return api.post<ResOrder>(`/v1/client/orders/active/create`,data)
    }
    public static cancelOrderActive(id: number) {
        return api.post<ResOrder>(`/v1/client/orders/${id}/cancel`)
    }
    public static cancelDriverActive(id: number) {
        return api.post<ResOrder>(`/v1/driver/orders/${id}/cancel`)
    }
    public static completeDriverActive(id: number) {
        return api.post<ResOrder>(`/v1/driver/orders/${id}/complete-successfully`)
    }
}