import {useAppSelector} from "@hooks/useAppSelector";
import {ReauthorizationReq, User} from "@typesApp/userApi";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {useEffect} from "react";
import {reauthorization, updateAuth} from "@store/slices/userSlice";
interface userAuthData {
    auth: boolean,
    user: User | null
}
export const useAuth = () => {
    const auth = useAppSelector(({user}) => user.auth)
    const user = useAppSelector(({user}) => user.user)
    const dispatch = useAppDispatch()
    useEffect(() => {
       const intervalId =  setInterval(() => {
            const token: string = localStorage.getItem('access_token')  ?
                JSON.parse(localStorage.getItem('access_token') ?? " ") : ''
            if(token){
                dispatch(updateAuth(true))
            } else {
                dispatch(updateAuth(false))
            }
        },300)
        return () => clearInterval(intervalId)
    },[])
    useEffect(() => {
        const intervalId = setInterval(() => {
            const time = localStorage.getItem('access_token_expires')
                ? JSON.parse(localStorage.getItem('access_token_expires') ?? " ")
                : '';

            if (time) {
                const expirationTime = new Date(time).getTime();
                const currentTime = new Date().getTime();
                const tenMinutesInMilliseconds = 10 * 60 * 1000;
                // Проверка за 10 минут до истечения срока действия
                if (expirationTime - currentTime <= tenMinutesInMilliseconds && expirationTime < currentTime) {
                    const data: ReauthorizationReq = {
                        refreshToken: JSON.parse(localStorage.getItem('refresh_token') ?? " ")
                    }
                    dispatch(reauthorization(data))
                }
            }
        }, 1000);

        // Чистка интервала при размонтировании компонента
        return () => clearInterval(intervalId);
    },[])
    return {
        auth,
        user
    }
}
