import './notification.scss'

export enum typeNotification {
    INFO,
    ERROR,
    SUCCESS,
    WARNING
}
export class Notification {

    static create(content: string, type: typeNotification): void {
        const root = document.getElementById('notification')

        if (!root) return;

        const container = document.createElement('div')
        const text = document.createElement('p')
        const title = document.createElement('p')

        text.textContent = content
        title.textContent = 'Информация'
        text.classList.add('text')
        title.classList.add('text')
        title.classList.add('title')
        root.style.position = 'fixed'
        root.classList.add('list')
        container.classList.add('notification-modal')

        switch (type) {
            case typeNotification.SUCCESS:
                title.textContent = 'Успех'
                container.classList.add('success')
                break
            case typeNotification.WARNING:
                title.textContent = 'Предупреждение'
                container.classList.add('warning')
                break
            case typeNotification.ERROR:
                title.textContent = 'Ошибка'
                container.classList.add('error')
                break
            default:
                break
        }

        container.style.transition = 'transform 0.5s ease-in-out';
        container.appendChild(title)
        container.appendChild(text)


        if (root.firstChild) {
            root.insertBefore(container, root.firstChild)
        } else {
            root.appendChild(container)
        }

        setTimeout(() => {
            container.style.transform = 'translateX(0)';
        }, 20);

        setTimeout(() => {
            container.style.transform = 'translateX(100%)';
            setTimeout(() => {
                root.removeChild(container);
            }, 500);
        }, 3000);
    }
    static success(content: string): void {
        Notification.create(content, typeNotification.SUCCESS)
    }
    static error(content: string): void {
        this.create(content, typeNotification.ERROR)
    }
    static warning(content: string): void {
        Notification.create(content, typeNotification.WARNING)
    }
    static info(content: string): void {
        Notification.create(content, typeNotification.INFO)
    }

}
