import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {  Modal } from 'react-bootstrap'
import styles from './EnterPhone.module.scss'
import {InputText} from "@common/components/InputText/InputText";
import {SubmitButton} from "@common/components/SubmitButton/SubmitButton";
import combiningClasses from "@utils/combiningClasses";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {useAppSelector} from "@hooks/useAppSelector";
import {RefreshPasswordReq, RefreshSendCodeReq, RegisterReq} from "@typesApp/userApi";
import {onboardingSendCode, refreshPassword, refreshSendCode, registerStep2} from "@store/slices/userSlice";
import {Notification} from "@utils/Notification/Notification";
interface EnterPhoneProps {
    onHide: () => void,
    show: boolean,
    type: 'register' | 'resetPassword',
}

export const EnterPhone: FC<EnterPhoneProps> = ({show,onHide,type}) => {

  const [code, setCode] = useState<string>('')
  const [minutes, setMinutes] = useState<number>(3);
  const [seconds, setSeconds] = useState<number>(0);
  const [isTimer, setIsTimer]  = useState<boolean>(true)
  const dispatch = useAppDispatch()
  const user = useAppSelector(({user}) => user.user)
  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          clearInterval(timer);
        }
      }
    }, 1000);
    if(minutes === 0 && seconds === 0){
      setIsTimer(false)
    } else {
      setIsTimer(true)
    }
    return () => {
      clearInterval(timer);
    };
  }, [minutes, seconds]);

  useEffect(() => {
    if(show){
      setMinutes(3);
      setSeconds(0);
    }

  }, [show]);
  
  const onChangeCode = (e: ChangeEvent) => {
    const code: string = (e.target as HTMLInputElement).value
      setCode(code)
  }
  const onSendCodeReport  = async () => {
    if(!isTimer && user ) {
        const data: RefreshSendCodeReq = {
          phone: user.phone,
          role: user.role
        }
       if(type === 'resetPassword') await dispatch(refreshSendCode(data))
       if(type === 'register') await dispatch(onboardingSendCode(data))
      setCode('')
      setIsTimer(prev => !prev)
      setMinutes(3)
      setSeconds(0)
      return
    }
  }

  const onSubmit = async () => {
    if(user?.phone_code_expired_datetime){
      const limitDate = new Date(user?.phone_code_expired_datetime)
      const nowDate = new Date()
      if(limitDate.getMinutes() < nowDate.getMinutes()){
        Notification.error('срок годности кода истёк')
        return
      }
    }
     if(user && type === 'register'){
       const data: RegisterReq = {
         onboarding_id: user.id,
         phone: user.phone,
         phone_code: Number(code)
       }
       dispatch(registerStep2(data))
     }
    if(user && type === 'resetPassword'){
      const data: RefreshPasswordReq = {
        phone: user.phone,
        phone_code: Number(code),
        role: user.role
      }
      await dispatch(refreshPassword(data))
    }
      setCode('')
  }
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title className='fb f25' id="contained-modal-title-vcenter">
          Подтверждение номера
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.alert}>
          <p className='fm f16'>На номер отправлен SMS с кодом. Введите его в поле и подтвердите в течении трёх минут.</p>
        </div>
        <div className={combiningClasses(styles.flex, 'fb','f16')}>
          <p className={styles.label}>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</p>
          <div className={styles.code}>
          <InputText  width='100%' value={code} onChange={onChangeCode} placeholder='Код'/>
          </div>
          <p onClick={onSendCodeReport} className={[styles.report, isTimer? styles.noactive : ""].join(' ')}>Отправить повторно</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <SubmitButton text='Подтвердить' onClick={onSubmit}/>
      </Modal.Footer>
    </Modal>
  )
}
