import {ReactNode} from "react";

export const PASSWORD_HELP: ReactNode =
    <div>
        <p style={{textAlign: "left"}}>1. Поле не должно быть пустым</p>
        <p style={{textAlign: "left"}}>2. Миниимум 8 символов</p>
        <p style={{textAlign: "left"}}>3. Минимум 1 цифра</p>
        <p style={{textAlign: "left"}}>4. Латинские буквы </p>
        <p style={{textAlign: "left"}}>5. Минимум 1 буква</p>
    </div>
export const TO_HELP: ReactNode =
    <div>
        <p style={{textAlign: "left"}}>Для заполнения поля выбирите точку на карте</p>
    </div>
export const TYPE_HELP: ReactNode =
    <div>
        <p style={{textAlign: "left"}}>Выбирите тип заказа</p>
    </div>
export const COMMENT_HELP: ReactNode =
    <div>
        <p style={{textAlign: "left"}}>Поле не обязательное</p>
    </div>
export const PASSWORD_REPORT_HELP: ReactNode =
    <div>
        <p>Пароли должны совпадать</p>
    </div>
export const NAME_HELP: ReactNode =
    <div>
        <p>1. Поле не должно быть пустым</p>
    </div>

export const LOGIN_HELP: ReactNode =
    <div>
        <p>1. Поле не должно быть пустым</p>
        <p>2. Должны быть латинские буквы</p>
    </div>
export const EMAIL_HELP: ReactNode =
    <div>
        <p>1. Должен быть правильный адрес электронной почты</p>
    </div>
export const PHONE_HELP: ReactNode =
    <div>
        <p>1. Должен быть правильный номер телефона</p>
    </div>
export const AVATAR_HELP: ReactNode =
    <div>
        <p>Изображение не обязательно</p>
    </div>
export const TASK_TITLE_HELP: ReactNode =
    <div>
        <p>1. Поле не должно быть пустым</p>
        <p>2. Максимальная длина 100 символов</p>
    </div>