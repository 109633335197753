import React, {useEffect, useRef, useState} from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import {Order} from "@typesApp/order";
import {useNavigate} from "react-router-dom";
import {clearOrder, takeOrder} from "@store/slices/orderSlice";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {getUser} from "@store/slices/userSlice";

interface MarkerOrderProps {
    orderActive: Order
}
const iconDefault = L.divIcon({
    className: 'custom-icon',
    html: `<div style="background: white; width: 200px; height: 100px"></div>`
});
const MarkerOrder: React.FC<MarkerOrderProps> = ({ orderActive }) => {
    const [icon,setIcon] = useState<L.DivIcon>(iconDefault)
    const [idButton, setIdButton] = useState<string>('')
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        const id = `button-${Math.floor(Math.random() * 10000).toString()}`
        const icon = L.divIcon({
            className: 'custom-icon',
            html: `<div class="icon-order">
                    <p class="fb f12">${orderActive.location_start.address}</p>
                    <p class="fm f10" style="color: gray;">${orderActive.type}</p>
                    <div style="width: 100%; column-gap: 10px;" class="d-flex align-items-center justify-content-end">
                        <button class=" fm f12 submit" id="submit-${id}">Взяться</button>
                        <button class=" fm f12 detail" id="detail-${id}">Детали</button>
                    </div>
                    </div>`
        });
        setIdButton(id)
        setIcon(icon)
    }, [orderActive]);

    useEffect(() => {
        if (icon) {

            const buttonDetail = document.getElementById(`detail-${idButton}`);
            const buttonSubmit = document.getElementById(`submit-${idButton}`);
            if (buttonDetail && buttonSubmit) {
                buttonDetail.addEventListener('click', openDetail);
                buttonSubmit.addEventListener('click', take);
            }

            return () => {
                if (buttonDetail && buttonSubmit) {
                    buttonDetail.removeEventListener('click', openDetail);
                    buttonSubmit.removeEventListener('click', take);
                }
            };
        }
    }, [icon]);

    const openDetail = (e: MouseEvent) => {
        navigate(`/orders/${orderActive.id}`)
        e.stopPropagation();
    }
    const take = async (e: MouseEvent) => {
       await dispatch(takeOrder(orderActive?.id))
        await dispatch(getUser())
        e.stopPropagation();
    }


    return (
        <Marker position={
            [
                orderActive.location_start.lat,
                orderActive.location_start.lot
            ]
        } icon={icon} />
    );
};

export default MarkerOrder;