import styles from './style.module.scss'
import {OrderModeAll} from "@typesApp/order";
import {FC} from "react";
import combiningClasses from "@utils/combiningClasses";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {useNavigate} from "react-router-dom";
interface OrderElementProps{
    order: OrderModeAll
}
export const OrderElement: FC<OrderElementProps> = ({order}) => {
    const navigate = useNavigate()
    const click = () => {
       navigate(`/orders/${order.id}`)
    }
    return (
        <div onClick={click} className={styles.card}>
            <p className={combiningClasses('fm', 'f16', styles.type)}>{order.type.value}</p>
            <p className={combiningClasses('fm', 'f14', styles.status)}>{order.status.value}</p>
        </div>
    )
}