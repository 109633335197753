import styles from './style.module.scss'
import {ChangeEvent, FC, useState} from "react";
import combiningClasses from "@utils/combiningClasses";
import showSvg from '@assets/icon/show-password.svg'
import hideSvg from '@assets/icon/hide-password.svg'
interface InputTextProps {
    value: string,
    onChange: (e: ChangeEvent) => void,
    placeholder: string,
    width?: string,
}
export const InputPassword: FC<InputTextProps> = ({width,value,placeholder, onChange}) => {
    const [isShow, setIsShow] = useState<boolean>(false)
    return (
        <div className={styles.wrap}>
            <input
                onChange={onChange}
                placeholder={placeholder}
                className={combiningClasses(styles.input, 'fm', 'f14')}
                type={isShow ? 'text' : 'password'}
                style={{width}}
                value={value}
            />
            {
                isShow ? <img  className={styles.icon} onClick={() => setIsShow(false)} src={showSvg} alt="Спрятать"/>
                    : <img  className={styles.icon} onClick={() => setIsShow(true)} src={hideSvg} alt="Показать"/>
            }
        </div>
    )
}