import React from 'react';
import styles from './styles.module.scss'
import {useNavigate} from "react-router-dom";
import {SubmitButton} from "@common/components/SubmitButton/SubmitButton";
import combiningClasses from "@utils/combiningClasses";
const NotFoundPage = () => {
    const navigate = useNavigate()
    const back = () => {
        navigate('/')
    }
    return (
        <div style={{minHeight: "100vh"}} className='d-flex flex-column gap-2 align-items-center justify-content-center h-100'>
            <p className={combiningClasses(styles.text,'fb','f30')}>Кажется страница не найдена</p>
            <SubmitButton text='Вернутся' onClick={back}/>
        </div>
    );
};

export default NotFoundPage;