import { useMap } from "react-leaflet";
import { LatLngExpression } from "leaflet";
import { FC, useEffect } from "react";

interface ChangeStartLocationProps {
    center: LatLngExpression,
}

export const ChangeStartLocation: FC<ChangeStartLocationProps> = ({ center }) => {
    const map = useMap();

    useEffect(() => {
        // Обновляем карту только при изменении центра
        map.setView(center);
    }, [map, center]); // Указываем зависимости эффекта

    return null;
};
