import styles from './style.module.scss'
import {ChangeEvent, FC} from "react";
import combiningClasses from "@utils/combiningClasses";
import {InputMask, InputMaskChangeEvent} from "primereact/inputmask";
interface InputTextProps {
    value: string,
    onChange: (e: InputMaskChangeEvent) => void,
    placeholder: string,
    width?: string,
}
export const InputPhone: FC<InputTextProps> = ({width,value,placeholder, onChange}) => {
    return <InputMask
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={combiningClasses(styles.input,'fm','f14')}
        type='text'
        style={{width}}
        mask={'+7 (999) 999-99-99'}
    />
}