import styles from './style.module.scss'
import {Col, Container, Row} from "react-bootstrap";
import {InputPassword} from "@common/components/InputPassword/InputPassword";
import {SubmitButton} from "@common/components/SubmitButton/SubmitButton";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {login, registerStep1, updateTypeForm} from "@store/slices/userSlice";
import {typeForm} from "@enums/auth";
import {ChangeEvent, useState} from "react";
import {InputPhone} from "@common/components/InputPhone/InputPhone";
import {InputMaskChangeEvent} from "primereact/inputmask";
import {LoginReq} from "@typesApp/userApi";
import {formatPhoneNumber} from "@utils/formatPhone";
import {Notification, typeNotification} from "@utils/Notification/Notification";

export const LoginForm = () => {
    const [password,setPassword] = useState<string>('')
    const [phone,setPhone] = useState<string>('')
    const dispatch = useAppDispatch()
    const onNav = () => {
        dispatch(updateTypeForm(typeForm.REGISTER))
    }
    const onReset = () => {
        dispatch(updateTypeForm(typeForm.RESET))
    }
    const onChangePassword = (e: ChangeEvent) => {
        const password: string =  (e.target as HTMLInputElement).value
        setPassword(password)
    }
    const onChangePhone = (e: InputMaskChangeEvent) => {
        setPhone(e.target.value as string)
    }
    const onSubmit = () => {
        if(password.length < 8)  {
            Notification.error('У пароля должно быть минимум 8 символов')
            return;
        }
        if(!/\d+/i.test(password)) {
            Notification.error('У пароля должна быть минимум 1 цифра')
            return;
        }
        if(!/[a-z]+/i.test(password)) {
            Notification.error('У пароля должeн быть минимум 1 латинский символ')
            return;
        }
        if(phone.length){
            const data: LoginReq = {
                phone: formatPhoneNumber(phone),
                password,
            }
            dispatch(login(data))
        } else {
            Notification.error('Поле номера телефона не заполнено')
        }
    }
    return (
        <Container fluid>
            <Row>
                <Col style={{minHeight: '100vh'}} className='d-flex align-items-center justify-content-center'>
                    <div className={styles.form}>
                        <h1 className='fb f25'>Вход</h1>
                        <div className={styles.content}>
                            <InputPhone width='100%' value={phone} onChange={onChangePhone} placeholder='+9 (999) 999-99-99'/>
                            <div className={styles.reset}>
                                <InputPassword width='100%' value={password} onChange={onChangePassword}
                                               placeholder='Пароль'/>
                                <p className='fm f16' onClick={onReset}>Востановить доступ</p>
                            </div>
                            <SubmitButton width='100%' text='Войти' onClick={onSubmit}/>
                            <p className='fm f16' onClick={onNav} >Зарегистрироваться</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
