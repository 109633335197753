import styles from './styles.module.scss'
import {FC} from "react";
interface IconButtonProps {
    onClick: () => void,
    icon: string,
    width?: string,
}
export const IconButton:FC<IconButtonProps> = ({ icon,onClick,width}) => {
    return <button className={styles.icon} onClick={onClick}>
        <img style={{width}} src={icon} alt="кнопка"/>
    </button>
}