import styles from './styles.module.scss'
import {FC} from "react";
interface SubmitButtonProps {
    text: string,
    onClick: () => void,
    width?: string,
    color?: string,
}
export const SubmitButton:FC<SubmitButtonProps> = ({text, color,onClick,width}) => {
    return <button style={{width,background: color ? color : ''}} className={styles.button} onClick={onClick}>{text}</button>
}