import {Tab, Tabs} from "react-bootstrap";
import styles from './style.module.scss'
import {FC, useEffect, useState} from "react";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {getUser, logout} from "@store/slices/userSlice";
import {IconButton} from "@common/components/IconButton/IconButton";
import ExitIcon from "@assets/icon/exit.svg";
import {User} from "@typesApp/userApi";
import {formatRole} from "@utils/formatRole";
import combiningClasses from "@utils/combiningClasses";
import {ListOrders} from "@pages/MapPage/components/ListOrders/ListOrders";
import {activateOrderDriver, clearOrders, clearOrdersState, deactivateOrderDriver} from "@store/slices/orderSlice";
import {clearMapState, updateUserAddress, updateUserLocation, updateUserMarker} from "@store/slices/mapSlice";
import {Role} from "@enums/role";
import {CreateOrder} from "@pages/MapPage/components/CreateOrder/CreateOrder";
import {useAppSelector} from "@hooks/useAppSelector";
import {MapApi} from "@api/MapApi";
import {NO_ADDRESS} from "@const/map";
import {SubmitButton} from "@common/components/SubmitButton/SubmitButton";
import {formatTime} from "@utils/formatTime";
import {Notification} from "@utils/Notification/Notification";
import {ActiveOrder} from "@pages/MapPage/components/ActiveOrder/ActiveOrder";

interface PanelActionsProps {
    user: User | null,
}
export const PanelActions: FC<PanelActionsProps> = ({user}) => {
    const [activeTab,setActiveTab] = useState<string>('decoration')
    const isProgressActive = useAppSelector(({order})=> order.isProgressOrder)
    const dispatch = useAppDispatch()
    const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false);
    const currentOrder = useAppSelector(({user}) => user.currentUserOrder )
    const [timer, setTimer] = useState<number>(0); // например, в секундах
    const activateOrder = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    await dispatch(activateOrderDriver({
                        location_activate: {
                            lat: latitude,
                            lot: longitude
                        }
                    }))
                    await dispatch(getUser())
                },
                (error) => {
                    console.log(error.message)
                }
            );
        } else {
            console.log("Не разрешён доступ к местоположению")
        }
    }
    const onComplete = async () => {
        if(user?.driver_data?.is_busy) {
            Notification.warning("Сначала завершите текущий заказ")
        } else if(user?.driver_data?.is_activate === 1) {
            await dispatch(deactivateOrderDriver())
            await dispatch(getUser())
            dispatch(clearOrders())
        }

    }
    useEffect(() => {
        if(user?.role === Role.DRIVER){
            setActiveTab('orderHistory')
        }
        if(user?.role === Role.CLIENT && currentOrder){
            setActiveTab('orderActive')
        }
        if(user?.role === Role.DRIVER && user?.driver_data?.is_activate === 1){
            setIsTimerRunning(true)
        } else {
            setIsTimerRunning(false)
        }
    }, [user]);
    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (isTimerRunning) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isTimerRunning]);
    const selectTab = (key: string | null) => {
        setActiveTab(key as string)
    }
    const onLogout = () => {
        dispatch(logout())
        dispatch(clearOrdersState())
        dispatch(clearMapState())
    }
    return (
        <div className={styles.content}>
            <div className='d-flex justify-content-start align-items-center' style={{columnGap: 10}}>
                <p className={combiningClasses('fb','f16', styles.name)}>{user?.name}</p>
                <p className={combiningClasses('fb','f14', styles.role)}>{formatRole(user?.role)}</p>
                <div className={styles.exit}>
                    <IconButton onClick={onLogout} icon={ExitIcon}/>
                </div>
            </div>
            <div>
                {
                    user?.role === Role.DRIVER && user?.driver_data?.is_activate === 0 ?
                        <div>
                            <SubmitButton color={'#058fe0'} text='Начать работу' onClick={activateOrder}/>
                        </div>: null
                }
                {
                    user?.role === Role.DRIVER && user?.driver_data?.is_activate === 1 ?
                        <div>
                            <SubmitButton color={'#058fe0'} text='Завершить' onClick={onComplete}/>
                            <p className='fm f14'>Время работы: {formatTime(timer)}</p>
                        </div>: null
                }
            </div>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={activeTab}
                    onSelect={selectTab}
                    className={styles.tabs}
                >
                    {
                        (() => {
                            switch (user?.role){
                                case Role.CLIENT:
                                case Role.ADMIN:
                                    if(!currentOrder){
                                        return (
                                            <Tab eventKey="decoration" title="Оформление заказа">
                                                <CreateOrder/>
                                            </Tab>
                                        )
                                    } else {
                                        return null
                                    }
                                case Role.DRIVER:
                                    return null
                                default:
                                    return
                            }
                        })()
                    }
                    {currentOrder && user ? (
                        <Tab eventKey="orderActive" title="Активный заказ">
                            <ActiveOrder order={currentOrder} user={user}/>
                        </Tab>
                    ):null}
                    <Tab eventKey="orderHistory" title="История заказов">
                        <ListOrders/>
                    </Tab>
                </Tabs>
        </div>
    );
}