import axios from 'axios'
import {API_URL} from "@const/index";
const api = axios.create({
    baseURL: API_URL
})
api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
export default api