import React, { FC, useEffect, useRef, useState } from "react";
import { LatLngExpression, Map as LeafletMap } from "leaflet";
import { MapContainer, TileLayer, useMapEvent } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import styles from "./style.module.scss";

import { useAppSelector } from "@hooks/useAppSelector";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { updateUserAddress, updateUserMarker } from "@store/slices/mapSlice";
import { MapApi } from "@api/MapApi";
import { Role } from "@enums/role";
import { NO_ADDRESS } from "@const/map";
import { Order } from "@typesApp/order";

import { MyLocation } from "@pages/MapPage/components/map/MyLocation/MyLocation";
import { ChangeStartLocation } from "@pages/MapPage/components/map/ChangeStartLocation/ChangeStartLocation";
import MarkerOrder from "@pages/MapPage/components/map/MarkerOrder/MarkerOrder";
import { MyLocationButton } from "@common/components/MyLocationButton/MyLocationButton";
import {OrderActiveRoute} from "@pages/MapPage/components/map/OrderActiveRoute/OrderActiveRoute";

interface LocationMarker {
    lat: number;
    lot: number;
}

interface Gis2MapProps {
    userLocation: LocationMarker | null;
    getMyLocation: () => void;
}

const defaultLocation: LatLngExpression = [55.9825, 37.1814]; // Зеленоград

export const Gis2Map: FC<Gis2MapProps> = ({ userLocation, getMyLocation }) => {
    const [centerLocation, setCenterLocation] = useState<LatLngExpression>(defaultLocation);
    const [ordersWithLocation, setOrdersWithLocation] = useState<Order[]>([]);
    const [activeOrderPosition, setActiveOrderPosition] = useState<LocationMarker | null>(null);
    const prevUserLocationRef = useRef<LocationMarker | null>(null);

    const dispatch = useAppDispatch();
    const userAddress = useAppSelector(({ map }) => map.userAddressLocation);
    const userMarkerPosition = useAppSelector(({ map }) => map.userPositionMarker);
    const user = useAppSelector(({ user }) => user.user);
    const activeOrders = useAppSelector(({ order }) => order.ordersActive);
    const currentOrder = useAppSelector(({user})=> user.currentUserOrder)

    const prevActiveOrdersRef = useRef<Order[]>([]);
    useEffect(() => {
        if(currentOrder?.location_start){
            setActiveOrderPosition({
                lat: currentOrder.location_start.lat,
                lot: currentOrder.location_start.lot
            })
        } else {
            setActiveOrderPosition(null)
        }
    }, [currentOrder]);
    useEffect(() => {
        const fetchOrders = async () => {
            const prevActiveOrders = prevActiveOrdersRef.current
            const newActiveOrders = activeOrders.filter(activeOrder =>
                !prevActiveOrders.some(prevOrder => prevOrder.id === activeOrder.id)
            );

            if (newActiveOrders.length === 0) return;

            const promises = newActiveOrders.map(async el => {
                const payload = [el.location_start.lat, el.location_start.lot];
                try {
                    const address = await MapApi.reverseGeocode(payload);
                    let str = address;
                    if (address.length >= 22) {
                        str = str.slice(0, 22) + "...";
                    }
                    return {
                        ...el,
                        location_start: {
                            ...el.location_start,
                            address: str
                        }
                    };
                } catch (err) {
                    console.log(err);
                    return {
                        ...el,
                        location_start: {
                            ...el.location_start,
                            address: '-'
                        }
                    };
                }
            });

            const orders = await Promise.all(promises);
            setOrdersWithLocation(prevOrders => [...prevOrders, ...orders]);

            prevActiveOrdersRef.current = activeOrders;
        };

        fetchOrders();
    }, [activeOrders]);

    useEffect(() => {
        if (userLocation && prevUserLocationRef.current !== userLocation) {
            setCenterLocation([userLocation.lat, userLocation.lot]);
            changeMyLocation([userLocation.lat, userLocation.lot]);
        }

        prevUserLocationRef.current = userLocation;
    }, [userLocation]);

    useEffect(() => {
        if (userMarkerPosition) {
                setCenterLocation([userMarkerPosition.lat, userMarkerPosition.lot]);
        }
    }, [userMarkerPosition]);

    const changeMyLocation = (value: LatLngExpression) => {
        if (user && (user.role === Role.ADMIN || user.role === Role.CLIENT)) {
            const payload = value as [number, number];
            dispatch(updateUserMarker({ lat: payload[0], lot: payload[1] }));
            MapApi.reverseGeocode(payload)
                .then(address => {
                    dispatch(updateUserAddress(address));
                })
                .catch(err => {
                    console.log(err);
                    dispatch(updateUserAddress(NO_ADDRESS));
                });
        }
    };

    // Hook to handle map center changes
    const MapEvents = () => {
        useMapEvent('moveend', (event) => {
            const map = event.target as LeafletMap;
            const center = map.getCenter();
            const location = centerLocation.toString().split(',')
            if(center.lat !== +location[0] || center.lng !== +location[1] ){
                setCenterLocation([center.lat, center.lng]);
            }
        });
        return null;
    };
    return (
        <div id="map-container" className={styles.map}>
            <MapContainer
                center={centerLocation}
                zoom={13}
                style={{ height: "100vh", width: "100%", zIndex: 12, position: "relative" }}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; OpenStreetMap contributors"
                />
                <ChangeStartLocation center={centerLocation} />
                <MyLocation changePosition={changeMyLocation} markerPosition={userMarkerPosition} />
                {user && (user.role === Role.DRIVER || user.role === Role.ADMIN) && !user?.driver_data?.is_busy &&
                    ordersWithLocation.map((card, i) => (
                        <MarkerOrder key={i} orderActive={card} />
                    ))}
                <MapEvents /> {/* Подключение события изменений центра карты */}
                {userMarkerPosition && activeOrderPosition ? (
                    <OrderActiveRoute orderLocation={activeOrderPosition} userLocation={userMarkerPosition}/>
                ):null}
            </MapContainer>
            <div className={styles.action}>
                <MyLocationButton onClick={getMyLocation} />
            </div>
        </div>
    );
};
