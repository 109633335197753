import styles from './style.module.scss'
import MyLocationIcon from '@assets/icon/location.svg'
import {FC} from "react";
interface MyLocationButtonProps{
    onClick: () => void
}
export const MyLocationButton: FC<MyLocationButtonProps> = ({onClick}) => {
    return (
        <button className={styles.location} onClick={onClick}>
            <img src={MyLocationIcon} alt="местоположение"/>
        </button>
    )
}