import React, {useEffect} from 'react';
import {RouterApp} from "@routes/RouterApp";
import {MapGis} from "@utils/MapGis";
import {API_MAP_KEY} from "@const/index";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {WindowSize} from "@typesApp/window";
import {updateSizeWindow} from "@store/slices/windowSlice";
export const mapGis = new MapGis(API_MAP_KEY)
function App() {
    const dispatch = useAppDispatch()
    useEffect(() => {
        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize',onResize)
        }
    },[])
    const onResize = (e: any) => {
        const window = e.target as Window
        const payload: WindowSize = {
            height: window.innerHeight,
            width: window.innerWidth
        }
        dispatch(updateSizeWindow(payload))
    }

  return (
    <div style={{ minHeight: '100vh'}}>
      <RouterApp/>
    </div>
  );
}

export default App;
