import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppState, MapState, OrderState} from "@typesApp/stateApp";
import { ActionModalOrder, Order, TypeSelectOrder} from "@typesApp/order";
import {OrderApi} from "@api/OrderApi";
import {Notification} from "@utils/Notification/Notification";
import {CreateOrderData, ReqActivate} from "@typesApp/ordersApi";
import {clearCurrentOrder} from "@store/slices/userSlice";

const initialState: OrderState =  {
    types: [],
    statuses: [],
    orders: [],
    ordersActive: [],
    order: null,
    loadOrder: false,
    loadOrdersHistory: false,
    isProgressOrder: null,
}

export const getTypesAndStatuses = createAsyncThunk(
    'order/getTypesAndStatuses',
    async (_,{rejectWithValue}) => {
        try {
            const {data} = await OrderApi.getStatusAndTypes()
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const getOrders = createAsyncThunk(
    'order/getOrders',
    async (_,{rejectWithValue, getState}) => {
        try {
            const {data} = await OrderApi.getOrders()
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const getOrdersDriver = createAsyncThunk(
    'order/getOrdersDrivers',
    async (_,{rejectWithValue, getState}) => {
        try {
            const {data} = await OrderApi.getOrdersDriver()
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const getOrdersActive = createAsyncThunk(
    'order/getOrdersActive',
    async (_,{rejectWithValue}) => {

        try {
            const {data} = await OrderApi.getOrdersActive()
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const getOrder = createAsyncThunk(
    'order/getOrder',
    async (id: number,{rejectWithValue}) => {
        try {
            const {data} = await OrderApi.getOrder(id)
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const takeOrder = createAsyncThunk(
    'order/takeOrder',
    async (id: number,{rejectWithValue}) => {
        try {
            const {data} = await OrderApi.takeOrder(id)
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const getOrderDriver = createAsyncThunk(
    'order/getOrderDriver',
    async (id: number,{rejectWithValue}) => {
        try {
            const {data} = await OrderApi.getOrderDriver(id)
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const getOrderDriverActive = createAsyncThunk(
    'order/getOrderDriverActive',
    async (id: number,{rejectWithValue}) => {
        try {
            const {data} = await OrderApi.getOrderDriverActive(id)
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const createOrder = createAsyncThunk(
    'order/createOrder',
    async (payload: CreateOrderData,{rejectWithValue}) => {
        try {
            const {data} = await OrderApi.createOrder(payload)
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const completeOrder = createAsyncThunk(
    'order/completeOrder',
    async (id: number,{rejectWithValue,dispatch}) => {
        try {
            const {data} = await OrderApi.completeDriverActive(id)
            dispatch(clearCurrentOrder())
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const cancelOrderClient = createAsyncThunk(
    'order/cancelOrderClient',
    async (id: number,{rejectWithValue, dispatch}) => {
        try {
            const {data} = await OrderApi.cancelOrderActive(id)
            dispatch(clearCurrentOrder())
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const cancelOrderDriver = createAsyncThunk(
    'order/cancelOrderDriver',
    async (id: number,{rejectWithValue,dispatch}) => {
        try {
            const {data} = await OrderApi.cancelDriverActive(id)
            dispatch(clearCurrentOrder())
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const activateOrderDriver = createAsyncThunk(
    'order/activateOrderDriver',
    async ( payload: ReqActivate,{dispatch,rejectWithValue}) => {
        try {
            const {data} = await OrderApi.activateOrder(payload)
            dispatch(clearCurrentOrder())
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const deactivateOrderDriver = createAsyncThunk(
    'order/deactivateOrderDriver',
    async ( _,{rejectWithValue}) => {
        try {
            const {data} = await OrderApi.deactivateOrder()
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
export const getOrderDriverHistory = createAsyncThunk(
    'order/getOrderDriverHistory',
    async (_,{rejectWithValue}) => {
        try {
            const {data} = await OrderApi.getOrdersHistory()
            return data
        } catch (e: any){
            return rejectWithValue(e?.response?.data)
        }
    }
)
const orderSlice = createSlice(
    {
        name: 'order',
        initialState,
        reducers: {
            clearOrdersState(state){
                state.order = null
                state.types = []
                state.statuses = []
                state.orders = []
                state.isProgressOrder = null
                state.ordersActive = []
            },
            clearOrder(state){
                state.order = null
            },
            clearActiveOrder(state) {
                state.isProgressOrder = null
            },
            updateDetailOrder(state, action: PayloadAction<Order>){
                state.order = action.payload
            },
            clearOrders(state){
                state.ordersActive = []
            }
        },
        extraReducers: (builder) => {
            builder.addCase(getTypesAndStatuses.fulfilled, (state,action) => {
                const types: TypeSelectOrder[]  = []
                const statuses: TypeSelectOrder[] = []
                for (const key in action.payload.types) {
                    types.push({
                        key,
                        value: action.payload.types[key]
                    })
                }

                for (const key in action.payload.statuses) {
                    statuses.push({
                        key,
                        value: action.payload.types[key]
                    })
                }
                state.statuses = statuses
                state.types = types
            })
            builder.addCase(getTypesAndStatuses.rejected, (state,action) => {
                const error: any = action.payload as any
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(getOrders.pending, (state) => {
                state.loadOrdersHistory = true
            })
            builder.addCase(getOrders.fulfilled, (state,action) => {
                state.orders = action.payload.data
                state.loadOrdersHistory = false
            })
            builder.addCase(getOrders.rejected, (state,action) => {
                const error: any = action.payload as any
                state.loadOrdersHistory = false
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(getOrderDriverHistory.pending, (state) => {
                state.loadOrdersHistory = true
            })
            builder.addCase(getOrderDriverHistory.fulfilled, (state,action) => {
                state.orders = action.payload.data
                state.loadOrdersHistory = false
            })
            builder.addCase(getOrderDriverHistory.rejected, (state,action) => {
                const error: any = action.payload as any
                state.loadOrdersHistory = false
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(getOrdersDriver.pending, (state) => {
                state.loadOrdersHistory = true
            })
            builder.addCase(getOrdersDriver.fulfilled, (state,action) => {
                state.orders = action.payload.data
                state.loadOrdersHistory = false
            })
            builder.addCase(getOrdersDriver.rejected, (state,action) => {
                const error: any = action.payload as any
                state.loadOrdersHistory = false
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(getOrdersActive.fulfilled, (state,action) => {
                state.ordersActive = action.payload.data

            })
            builder.addCase(getOrdersActive.rejected, (state,action) => {
                const error: any = action.payload as any
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(getOrder.fulfilled, (state,action) => {
                state.order = action.payload.data
                state.loadOrder = false
            })
            builder.addCase(getOrder.pending, (state) => {
                state.loadOrder = true
            })
            builder.addCase(getOrder.rejected, (state,action) => {
                state.order = null
                state.loadOrder = false
                const error: any = action.payload as any
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(getOrderDriver.fulfilled, (state,action) => {
                state.isProgressOrder = action.payload.data
                //state.loadOrder = false
            })
            builder.addCase(getOrderDriver.pending, (state) => {
                //state.loadOrder = true
            })
            builder.addCase(getOrderDriver.rejected, (state,action) => {
                state.isProgressOrder = null
                //state.loadOrder = false
                const error: any = action.payload as any
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(takeOrder.fulfilled, (state,action) => {
                state.isProgressOrder = action.payload.data
            })
            builder.addCase(takeOrder.pending, (state) => {
                //state.loadOrder = true
            })
            builder.addCase(takeOrder.rejected, (state,action) => {
                state.isProgressOrder = null
                const error: any = action.payload as any
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(getOrderDriverActive.fulfilled, (state,action) => {
                state.order = action.payload.data
                state.loadOrder = false
            })
            builder.addCase(getOrderDriverActive.pending, (state) => {
                state.loadOrder = true
            })
            builder.addCase(getOrderDriverActive.rejected, (state,action) => {
                state.order = null
                state.loadOrder = false
                const error: any = action.payload as any
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(deactivateOrderDriver.fulfilled, (state,action) => {
                Notification.success('Вы завершили работу')
            })
            builder.addCase(deactivateOrderDriver.rejected, (state,action) => {
                const error: any = action.payload as any
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(activateOrderDriver.fulfilled, (state,action) => {
                Notification.success('Вы в работе')
            })
            builder.addCase(activateOrderDriver.rejected, (state,action) => {
                const error: any = action.payload as any
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })

            builder.addCase(createOrder.rejected, (state,action) => {
                const error: any = action.payload as any
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(completeOrder.fulfilled, (state,action) => {
                Notification.success('Заказ завершён')
            })
            builder.addCase(completeOrder.rejected, (state,action) => {
                const error: any = action.payload as any
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })

            builder.addCase(cancelOrderClient.fulfilled, (state,action) => {
                Notification.success('Заказ отменён')
                state.isProgressOrder = null
            })
            builder.addCase(cancelOrderClient.rejected, (state,action) => {
                const error: any = action.payload as any
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })
            builder.addCase(cancelOrderDriver.fulfilled, (state,action) => {
                Notification.success('Заказ отменён')
                state.isProgressOrder = null
            })
            builder.addCase(cancelOrderDriver.rejected, (state,action) => {
                const error: any = action.payload as any
                if (error?.message){
                    Notification.error(error?.message)
                } else {
                    Notification.error('Ошибка сервера')
                }
            })

        }
    }
)

export const { clearOrders,updateDetailOrder, clearActiveOrder, clearOrdersState,clearOrder }  = orderSlice.actions
export default orderSlice.reducer