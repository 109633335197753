import React, { useEffect, useRef } from 'react';
import { Marker, useMap } from 'react-leaflet';
import 'leaflet-routing-machine';
import L from 'leaflet';
import { LocationMarker } from "@typesApp/map";
import StartIcon from "@assets/icon/startRoute.svg";
import EndIcon from "@assets/icon/endRoute.svg";

interface MapWithRouteProps {
    orderLocation: LocationMarker;
    userLocation: LocationMarker;
}
const startIcon = L.icon({
    iconUrl: StartIcon,
    iconSize: [50, 50], // Размер иконки
    iconAnchor: [12.5, 41], // Точка, где иконка якорится к координатам
    popupAnchor: [0, -41] // Точка всплывающего окна относительно якоря иконки
});
const endIcon = L.icon({
    iconUrl: EndIcon,
    iconSize: [50, 50], // Размер иконки
    iconAnchor: [12.5, 41], // Точка, где иконка якорится к координатам
    popupAnchor: [0, -41] // Точка всплывающего окна относительно якоря иконки
});
export const OrderActiveRoute: React.FC<MapWithRouteProps> = ({ orderLocation, userLocation }) => {
    const map = useMap();
    const routingControlRef = useRef<L.Control | null>(null);

    useEffect(() => {
        // Проверяем, что маркеры имеют корректные координаты
        if (!orderLocation || !userLocation) return;

        // Инициализация маршрута
        routingControlRef.current = L.Routing.control({
            waypoints: [
                L.latLng(userLocation.lat, userLocation.lot),
                L.latLng(orderLocation.lat, orderLocation.lot),
            ],
            routeWhileDragging: true,
            show: false,
            addWaypoints: false, // Запретить добавление новых точек
        }).addTo(map);
        const startMarker = L.marker([userLocation.lat, userLocation.lot], { icon: startIcon }).addTo(map);
        const endMarker = L.marker([orderLocation.lat, orderLocation.lot], { icon: endIcon }).addTo(map);

        // Чистка
        return () => {
            // Проверяем, существует ли routingControlRef.current, прежде чем вызывать метод удаления
            if (routingControlRef.current) {
                try {
                    map.removeControl(routingControlRef.current);
                } finally {
                    routingControlRef.current = null; // Обнуление ссылки на контрол
                }
            }
            // Убедимся, что маркеры все еще добавлены на карту перед их удалением
            try {
                map.eachLayer(layer => {
                    if (layer instanceof L.Marker && ((layer.getLatLng().lat === userLocation.lat && layer.getLatLng().lng === userLocation.lot) ||
                        (layer.getLatLng().lat === orderLocation.lat && layer.getLatLng().lng === orderLocation.lot))) {
                        map.removeLayer(layer);
                    }
                });
            } catch (error) {
                console.error("Ошибка при удалении маркеров:", error);
            }
        };
    }, [map, orderLocation, userLocation]);


    return null
};
