import styles from './style.module.scss'
import {useAppSelector} from "@hooks/useAppSelector";
import {OrderElement} from "@pages/MapPage/components/OrderElement/OrderElement";
import combiningClasses from "@utils/combiningClasses";
import {FC} from "react";
import {OrderSelect} from "@pages/MapPage/components/OrderSelect/OrderSelect";
interface ListOrdersProps{
    select?: boolean,
    onSelect?: (id: number) => void
}
export const ListOrders: FC<ListOrdersProps> = ({select,onSelect}) => {
    const orders = useAppSelector(({order}) => order.orders)
    if(select && onSelect){
        return (
            <div className={styles.list}>
                {
                    orders.slice(0,1000).length ? orders.map((order,i) =>
                            <OrderSelect onSelect={onSelect} key={i} order={order}/>)
                        : <p className={combiningClasses('fr','f16',styles.no)}>Еще не было ни одного заказа</p>
                }
            </div>
        )
    }
    return (
        <div className={styles.list}>
            {
                orders.slice(0,1000).length ? orders.map((order,i) =>
                        <OrderElement key={i} order={order}/>)
                    : <p className={combiningClasses('fr','f16',styles.no)}>Еще не было ни одного заказа</p>
            }
        </div>
    )
}