import styles from './style.module.scss'
import {OrderModeAll} from "@typesApp/order";
import {FC} from "react";
import combiningClasses from "@utils/combiningClasses";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {useNavigate} from "react-router-dom";
interface OrderSelectProps{
    order: OrderModeAll,
    onSelect: (id: number) => void
}
export const OrderSelect: FC<OrderSelectProps> = ({order, onSelect}) => {
    const click = () => {
       onSelect(order.id)
    }
    return (
        <div onClick={click} className={styles.card}>
            <p className={combiningClasses('fm', 'f16', styles.type)}>{order.type.value}</p>
            <p className={combiningClasses('fm', 'f14', styles.status)}>{order.status.value}</p>
        </div>
    )
}