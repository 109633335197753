import axios from "axios";
import {NO_ADDRESS} from "@const/map";

export class MapApi {
   public static reverseGeocode = async (payload: number[]): Promise<string> => {
        const [lat, lng] = payload;
        const {data} = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`);
        if(data.display_name) {
            return data.display_name
        } else {
            return NO_ADDRESS
        }
    };
}