import React, {FC, ReactNode, useState} from 'react'
import styles from './info.module.scss'
import {Dropdown} from "react-bootstrap";
import infoSvg from '@assets/icon/info.svg'
import './styles.scss'
import combiningClasses from "@utils/combiningClasses";
interface InfoProps {
    content: ReactNode,
}
export const Info:FC<InfoProps> = ({content}) => {
    const [show, setShow] = useState<boolean>(false)
    return (
        <div className={styles.hint}>
            <Dropdown onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} className="select-help"  show={show}>
                <Dropdown.Toggle as="div">
                    <img style={{opacity: show ? 1 : 0.3}} src={infoSvg} alt="info"/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item as='div'>
                        {content}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}
