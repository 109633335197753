import styles from './style.module.scss'
import {ChangeEvent, FC, useEffect, useState} from "react";
import combiningClasses from "@utils/combiningClasses";
import ArrowSVG from '@assets/icon/arrow.svg'
import {TypeSelectOrder} from "@typesApp/order";
interface SelectProps {
    value: TypeSelectOrder | null,
    onSelect: (value: TypeSelectOrder) => void,
    width?: string,
    list: TypeSelectOrder[]
}
export const Select: FC<SelectProps> = ({width, list,value, onSelect}) => {
    const [openList,setOpenList] = useState<boolean>(false)
    const [valueFormat,setValueFormat] = useState<string>('Нет данных')
    const updateOpenList = (e: any) => {
        setOpenList(prev => !prev)
    }
    const selected = (value: TypeSelectOrder) => {
        onSelect(value)
        setOpenList(false)
    }
    useEffect(() => {
        if(value){
            setValueFormat(value.value.length > 13 ?
                value.value.slice(0,13) + '...': value.value)
        }
        if(!value){
            setValueFormat('Нет данных')
        }
    }, [value]);
    return (
        <div className={styles.container} style={{width}}>
            <div className={styles.flex} onClick={updateOpenList}>
                <input
                    type='text'
                    className={combiningClasses(styles.input, 'fm', 'f14')}
                    disabled={true}
                    value={valueFormat}
                />
                {openList ?
                    <img src={ArrowSVG} alt="открыть"/> :
                    <img src={ArrowSVG} style={{transform: 'rotate(180deg)'}} alt="закрыть"/>}
            </div>
            {
                list.length && openList ? (
                    <div className={styles.list} style={{width}}>
                        {
                            list.map((el) => (
                                <div onClick={() => selected(el)} className={styles.listEl} key={el.key}>
                                    <p className='fb f14'>{el.value}</p>
                                </div>
                            ))
                        }
                    </div>
                ): null
            }
        </div>
    )
}