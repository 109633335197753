import styles from './style.module.scss'
import combiningClasses from "@utils/combiningClasses";
export const Pending = () => {
    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <p
                    className={combiningClasses('fm','f16', styles.text)}>
                    Ищем водителя...
                </p>
            </div>
        </div>
    )
}