import {Role} from "@enums/role";

export const formatRole = (role: Role| undefined): string => {
    switch (role){
        case Role.ADMIN:
            return 'Администратор'
        case Role.CLIENT:
            return 'Клиент'
        case Role.DRIVER:
            return 'Водитель'
        default:
            return 'Неизвестная роль'
    }
}