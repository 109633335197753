import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import NotFoundPage from "@pages/NotFoundPage/NotFoundPage";
import {LoginPage} from "@pages/LoginPage/LoginPage";
import {MapPage} from "@pages/MapPage/MapPage";
import {useAuth} from "@hooks/useAuth";
import {OrderDetail} from "@pages/OrderDetail/OrderDetail";

export const RouterApp = () => {
    const authUser = useAuth()

    const routes = createBrowserRouter([
        {
            path: '/',
            element: <Navigate to='/login'/>,
            errorElement: <NotFoundPage/>
        },
        {
            path: '/login',
            element: authUser.auth ? <Navigate to='/map'/> : <LoginPage/>,
            errorElement: <NotFoundPage/>
        },
        {
            path: '/map',
            element: !authUser.auth ? <Navigate to='/login'/> : <MapPage/>,
            errorElement: <NotFoundPage/>
        },
        {
            path: '/orders/:id',
            element: !authUser.auth ? <Navigate to='/login'/> : <OrderDetail/>,
            errorElement: <NotFoundPage/>
        },
        {
            path: '*',
            element: <NotFoundPage/>,
        },
    ])

    return <RouterProvider router={routes}/>
};
