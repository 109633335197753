import styles from './style.module.scss'

import {useEffect, useState} from "react";
import combiningClasses from "@utils/combiningClasses";
import {useAppSelector} from "@hooks/useAppSelector";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {clearOrder, getOrder, getOrderDriver, getOrderDriverActive, takeOrder} from "@store/slices/orderSlice";
import {format} from "date-fns";
import {MapApi} from "@api/MapApi";
import {Container, Spinner} from "react-bootstrap";
import {IconButton} from "@common/components/IconButton/IconButton";
import closeIcon from '@assets/icon/close.svg'
import {useNavigate, useParams} from "react-router-dom";
import {ru} from 'date-fns/locale'
import {Role} from "@enums/role";
import {SubmitButton} from "@common/components/SubmitButton/SubmitButton";
import {getUser} from "@store/slices/userSlice";

export const OrderDetail = () => {
    const dispatch = useAppDispatch()
    const order = useAppSelector(({order}) => order.order)
    const ordersActive = useAppSelector(({order}) => order.ordersActive)
    const load = useAppSelector(({order}) => order.loadOrder)
    const [address, setAddress] = useState<string>('-')
    const [comment, setComment] = useState<string>('-')
    const user = useAppSelector(({user})=> user.user)
    const { id } = useParams(); // Извлекает параметр id из адресной строки
    const navigate = useNavigate()

    useEffect(() => {
        if (id) {
            if(user?.role === Role.CLIENT) dispatch(getOrder(Number(id)));
            if(user?.role === Role.DRIVER) dispatch(getOrderDriverActive(Number(id)));
        }
    }, []);

    useEffect(() => {
        if (order) {
            if (order.client_comment?.length) {
                setComment(order.client_comment)
            } else {
                setComment('-')
            }
          if(order.location_start){
              MapApi.reverseGeocode([
                  order.location_start.lat,
                  order.location_start.lot
              ])
                  .then(address => {
                      setAddress(address)
                  })
                  .catch(err => {
                      console.log(err)
                      setAddress('-')
                  })
          }
        }
    }, [order]);
    const close = () => {
        navigate('/map')
        dispatch(clearOrder())
    }
    const onTakeOrder = async () => {
        if(order){
            navigate('/map')
           await dispatch(takeOrder(order?.id))
           await dispatch(clearOrder())
            await dispatch(getUser())
        }
    }
    if (order && !load) {
        return (
            <>
            <div className={styles.back}>
                <IconButton onClick={close} icon={closeIcon}/>
            </div>
        <Container>
            <div className={styles.detail}>
                <div className={styles.header}>
                <p className={combiningClasses('fb', 'f30', styles.title)}>Детали заказа</p>
                        {/*<p className={combiningClasses('fb', 'f16', styles.date)}>{format(new Date(order.date_start), 'dd MMMM yyyy г.',{locale: ru})}</p>*/}
                    </div>
                    <div className={styles.blockDate}>
                        <p className={combiningClasses('fm', 'f16', styles.type)}><span
                            style={{color: '#00600c', fontWeight: 700}}>Тип:</span> {order?.type}</p>
                    </div>
                    <div className={styles.block}>
                        <p className={combiningClasses('fm', 'f16', styles.status)}><span
                            style={{color: '#00600CFF', fontWeight: 700}}>Статус:</span> {order?.status}</p>
                    </div>
                    <div className={styles.block}>
                        <p className={combiningClasses('fm', 'f16', styles.address)}><span
                            style={{color: '#00600CFF', fontWeight: 700}}>Куда:</span> {address}</p>
                    </div>
                    <div className={styles.block}>
                        <p className={combiningClasses('fm', 'f16', styles.comment)}><span
                            style={{color: '#00600CFF', fontWeight: 700}}>Комментарий:</span> {comment}</p>
                    </div>
                {
                    user?.role === Role.DRIVER && order.status === 'Активен' ?
                        <SubmitButton text='Взять в работу' onClick={onTakeOrder}/>: null
                }
                </div>
            </Container>
            </>
        )
    }
    if (load) {
        return (
            <>
                <div className={styles.back}>
                    <IconButton onClick={close} icon={closeIcon}/>
                </div>
                <Container className='d-flex align-items-center justify-content-center' style={{height: '100vh'}}>
                    <div className={styles.spinner}>
                        <Spinner style={{width: 100, height: 100}} color='grey'/>
                    </div>
                </Container>
            </>

        )
    }
    return (
        <>
            <div className={styles.back}>
                <IconButton onClick={close} icon={closeIcon}/>
            </div>
            <Container className='d-flex align-items-center justify-content-center' style={{height: '100vh'}}>
                <div className={styles.spinner}>
                    <p className={combiningClasses('fb', 'f30', styles.no)}>Нет данных о заказе</p>
                </div>
            </Container>
        </>

    )
}