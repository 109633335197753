import {useAppSelector} from "@hooks/useAppSelector";
import {typeForm} from "@enums/auth";
import {LoginForm} from "@pages/LoginPage/components/LoginForm/LoginForm";
import {RegisterFormClient} from "@pages/LoginPage/components/RegisterFormClient/RegisterFormClient";
import {ResetForm} from "@pages/LoginPage/components/ResetForm/ResetForm";
import {EnterPhone} from "@modals/EnterPhone/EnterPhone";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {updateModalEnterCode} from "@store/slices/userSlice";

export const LoginPage = () => {
    const typeFormValue = useAppSelector(({user}) => user.typeForm )
    const openModal = useAppSelector(({user}) => user.modalEnterCode)
    const dispatch = useAppDispatch()
    const onClose = () => dispatch(updateModalEnterCode(false))

    switch (typeFormValue) {
        case typeForm.LOGIN:
            return (
                <>
                    <LoginForm/>
                    <EnterPhone onHide={onClose} show={openModal} type='register'/>
                </>
            )
        case typeForm.REGISTER:
            return (
                <>
                    <RegisterFormClient/>
                    <EnterPhone onHide={onClose} show={openModal} type='register'/>
                </>
            )
        case typeForm.RESET:
            return (
                <>
                    <ResetForm/>
                    <EnterPhone onHide={onClose} show={openModal} type='resetPassword'/>
                </>
            )
        default:
            return null;
    }

}