export function generatePassword(): string {
    const length = 8;
    const numbers = "0123456789";
    const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";

    let password = "";

    // Обязательные символы по правилам
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];

    const allCharacters = numbers + lowercaseLetters;

    // Добавить дополнительные случайные символы до необходимой длины
    for (let i = password.length; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * allCharacters.length);
        password += allCharacters[randomIndex];
    }

    // Перемешать пароль для случайности расположения
    password = password.split('').sort(() => Math.random() - 0.5).join('');

    return password;
}
