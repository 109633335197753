import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MapState} from "@typesApp/stateApp";
import {LocationMarker} from "@typesApp/map";
import {NO_ADDRESS} from "@const/map";

const initialState: MapState =  {
    userLocation: null,
    userAddressLocation: NO_ADDRESS,
    userPositionMarker: null
}
const mapSlice = createSlice(
    {
        name: 'map',
        initialState,
        reducers: {
            updateUserLocation(state,action: PayloadAction<LocationMarker | null>){
                state.userLocation = action.payload
            },
            updateUserAddress(state,action: PayloadAction<string>)  {
                state.userAddressLocation = action.payload
            },
            updateUserMarker(state,action: PayloadAction<LocationMarker | null>)  {
                state.userPositionMarker = action.payload
            },
            clearMapState(state){
                state.userPositionMarker = null
                state.userLocation = null
                state.userAddressLocation = NO_ADDRESS
            }
        },
    }
)

export const {updateUserLocation, clearMapState, updateUserMarker,updateUserAddress}  = mapSlice.actions
export default mapSlice.reducer