export const formatPhoneNumber = (input: string): string => {
    // Удаляем все символы, кроме цифр
    const digitsOnly = input.replace(/\D/g, '');
    // Заменяем +7 на 7
    if (digitsOnly.startsWith('7')) {
        return digitsOnly;
    }
    return '7' + digitsOnly.slice(1);
}
export const  formatPhoneNumberBack = (input: string): string => {
    // Извлекаем код города и остаток номера
    const cityCode = input.slice(1, 4);
    const firstPart = input.slice(4, 7);
    const secondPart = input.slice(7, 9);
    const thirdPart = input.slice(9, 11);

    // Формируем номер в требуемом формате
    return `+7 (${cityCode}) ${firstPart}-${secondPart}-${thirdPart}`;
}