import {
    ForgetPasswordReq,
    ForgetPasswordRes,
    LoginReq,
    LoginRes,
    OnboardingReq,
    OnboardingRes,
    ReauthorizationReq, RefreshPasswordReq, RefreshPasswordRes, RefreshSendCodeReq,
    RegisterReq,
    RegisterRes
} from "@typesApp/userApi";
import api from "@api/index";

export class AuthApi {
    public static registerStep1(data: OnboardingReq) {
        return api.post<OnboardingRes>('/v1/onboarding',data)
    }
    public static registerStep2(data: RegisterReq) {
        return api.post<RegisterRes>('/v1/register',data)
    }
    public static reauthorization(data: ReauthorizationReq) {
        return api.post<RegisterRes>('/v1/refresh-token',data)
    }
    public static login(data: LoginReq) {
        return api.post<LoginRes>('/v1/login',data)
    }
    public static logout() {
        return api.post('/v1/logout')
    }
    public static forgetPassword(data: ForgetPasswordReq) {
        return api.post<ForgetPasswordRes>('/v1/forget-password',data)
    }
    public static refreshPassword(data: RefreshPasswordReq) {
        return api.post<RefreshPasswordRes>('/v1/refresh-password',data)
    }
    public static refreshSendCode(data: RefreshSendCodeReq) {
        return api.post<RefreshPasswordRes>('/v1/refresh-user-code',data)
    }
    public static onboardingSendCode(data: RefreshSendCodeReq) {
        return api.post<RefreshPasswordRes>('/v1/refresh-onboarding-user-code',data)
    }
}