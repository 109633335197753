import {configureStore} from "@reduxjs/toolkit";
import {AppState} from "@typesApp/stateApp";
import userSlice from "@store/slices/userSlice";
import mapSlice from "@store/slices/mapSlice";
import windowSlice from "@store/slices/windowSlice";
import orderSlice from "@store/slices/orderSlice";

const store = configureStore<AppState>({
    reducer: {
        user: userSlice,
        map: mapSlice,
        window: windowSlice,
        order: orderSlice
    },
    devTools: true
})

export default store