import styles from './style.module.scss'
import {Gis2Map} from "@pages/MapPage/components/Gis2Map/Gis2Map";
import {useAppSelector} from "@hooks/useAppSelector";
import {useAppDispatch} from "@hooks/useAppDispatch";
import {updateUserAddress, updateUserLocation, updateUserMarker} from "@store/slices/mapSlice";
import {useEffect} from "react";
import {PanelActions} from "@pages/MapPage/components/PanelActions/PanelActions";
import {PanelActionsMobile} from "@pages/MapPage/components/PanelActionsMobile/PanelActionsMobile";
import {getUser} from "@store/slices/userSlice";
import {MapApi} from "@api/MapApi";
import {NO_ADDRESS} from "@const/map";
import {
    clearOrder,
    getOrderDriverHistory,
    getOrders,
    getOrdersActive,
    getOrdersDriver,
    getTypesAndStatuses
} from "@store/slices/orderSlice";
import {Role} from "@enums/role";
import {Pending} from "@modals/Pending/Pending";
export const MapPage = () => {
    const user = useAppSelector(({user}) => user.user)
    const isProgressActive = useAppSelector(({order})=> order.isProgressOrder)
    const userLocation = useAppSelector(({map}) => map.userLocation)
    const dispatch = useAppDispatch()
    const widthWindow = useAppSelector(({window}) => window.widthWindow)
    const isPendingOrder = useAppSelector(({user})=> user.isPendingOrder)
    useEffect(()  => {
        getLocationAndAddress()
    },[])
    useEffect(() => {
        if(!user) dispatch(getUser())
    }, []);
    useEffect(() => {
        dispatch(getTypesAndStatuses())
    }, [user]);
    useEffect(() => {
        dispatch(getUser())
    }, []);
    useEffect(() => {
        if(user?.role === Role.CLIENT){
            dispatch(getOrders())
        }
        if(user?.role === Role.DRIVER){

            dispatch(getOrderDriverHistory())
        }
    }, [user]);
    useEffect(() => {
        let interval: any
        if(user?.role === Role.DRIVER &&
            user?.driver_data?.is_activate &&
            !user.driver_data?.is_busy
        ){
           interval = setInterval(() => {
                    dispatch(getOrdersActive())
            },3000)
        } else  {
            clearInterval(interval)
        }
        return () => clearInterval(interval)
    }, [user]);
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    dispatch(updateUserLocation({
                        lat: latitude,
                        lot: longitude
                    }))
                },
                (error) => {
                    console.log(error.message)
                    dispatch(updateUserLocation(null))

                }
            );
        } else {
            dispatch(updateUserLocation(null))
        }
    };
    const getLocationAndAddress = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    dispatch(updateUserLocation({
                        lat: latitude,
                        lot: longitude
                    }))
                    dispatch(updateUserMarker({
                        lat: latitude,
                        lot: longitude
                    }))
                    MapApi.reverseGeocode([latitude,longitude])
                        .then(address => {
                            dispatch(updateUserAddress(address))
                        })
                        .catch(err => {
                            console.log(err)
                            dispatch(updateUserAddress(NO_ADDRESS))
                        })
                },
                (error) => {
                    console.log(error.message)
                    dispatch(updateUserLocation(null))

                }
            );
        } else {
            dispatch(updateUserLocation(null))
        }
    }
    if(widthWindow > 890) {
        return (
            <>
            <div className={styles.page}>
                <div className={styles.panel}><PanelActions user={user}/></div>
                <div className={styles.map}>
                    <Gis2Map getMyLocation={getLocationAndAddress} userLocation={userLocation}/>
                </div>
            </div>
                {isPendingOrder ? <Pending/>: null}
            </>
        )
    } else {
        return (
            <>
            <div className={styles.pageMobile}>
                <PanelActionsMobile getMyLocation={getLocationAndAddress} user={user}/>
                <Gis2Map getMyLocation={getLocationAndAddress} userLocation={userLocation}/>
            </div>
                {isPendingOrder ? <Pending/>: null}
            </>
        )
    }

}